//Color
$dark: #000;
/*
==============================
Fonts
==============================
*/

$ff: "Roboto", sans-serif;
$sing: "SignPainter", sans-serif;
