.b-index {
  background-image: url("/images/body-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include vw(
          $padding: 150 0 0
  );

  &_wrap {
    background-color: rgba(255, 255, 255, 0.4);
    text-align: center;
    @include vw(
            $border: 1 solid #a26026,
            $padding: 30 25,
            $border-radius: 20
    );
    &_title {
      position: relative;
      width: 100%;
      @include vw(
              $margin: 0 0 20
      );
      &:after,
      &:before {
        content: '';
        background-image: url("/images/title-line.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        left: 0;
        top: 50%;
        @include vw(
                $width: 210,
                $height: 8
        );
        @include respond-to(small) {
          display: none;
        }
      }
      &:after {
        left: auto;
        right: 0;
      }
      img {
        width: 100%;
        @include vw(
                $max-width: 620
        );
      }
    }
    p {
      font-weight: 600;
      @include vw(
              $font-size: 22,
              $max-width: 710,
              $margin: 0 auto 25
      );
      @include respond-to(small) {
        font-size: 18px;
      }
    }
    &_cosmi {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include respond-to(small) {
        flex-direction: column;

      }
      @include vw(
              $margin: 0 0 15
      );
      &_item {
        width: 33%;
        @include vw(
                $padding: 0 15
        );
        @include respond-to(small) {
          width: 100%;
          margin-bottom: 20px;
        }
        &:first-child {
          img {
            @include vw(
                    $max-width: 257
            );
          }
        }
        &:nth-child(2) {
          display: flex;
          flex-direction: column;
          align-items: center;
          a {
            @include vw(
                    $font-size: 30,
                    $border: 1 solid #a26026,
                    $padding: 30 0,
                    $max-width: 300,
                    $border-radius: 20,
                    $margin: 0 0 20
            );
            width: 100%;
            text-decoration: none;
            display: inline-block;
            font-weight: bold;
            color: #096791;
            background-color: #fff;
            position: relative;
            &:before {
              content: '';
              position: absolute;
              width: 100%;
              transition: all .3s;
              @include vw(
                      $max-width: 290,
                      $top: 5,
                      $left: 4,
                      $padding: 43 0,
                      $border-radius: 20,
                      $border: 3 solid #a26026
              );
              @include respond-to(small) {
                display: none;
              }
            }
            &:last-child {
              margin-bottom: 0;
            }
            &:hover,
            &:active,
            &:focus {
              color: #a26026;
              border-color: #096791;
              overflow: hidden;
              &:before {
                transition: all .6s;
                border-color: #096791;
                transform: scale(1.03);
              }
            }
          }
        }
        &:last-child {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          span {
            font-family: $sing;
            color: #096791;
            text-transform: uppercase;
            font-weight: 600;
            line-height: 1.2;
            display: inline-block;
            @include vw(
                    $margin: 0 0 60
            );
            @include vw(
                    $font-size: 28
            );
            @include respond-to(small) {
              margin-bottom: 25px;
              font-size: 19px;
            }
          }
          .b-index_social {
            display: flex;
            justify-content: center;
            a {
              background-image: url("/images/social-icons.png");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: top 0 left 0;
              display: inline-block;
              text-decoration: none;
              @include vw(
                      $height: 64,
                      $width: 64,
                      $margin-right: 30
              );
              &:hover,
              &:active,
              &:focus {
                transition: all .3s;
                opacity: .7;
              }
              &:nth-child(2) {
                @include vw(
                        $background-position: top 0 left -95.4
                );
                @include respond-to(small) {
                  background-position: top 0px left -65.78px;
                }
              }
              &:last-child {
                margin-right: 0;
                @include vw(
                        $background-position: top 0 right 0
                );
              }
            }
          }
        }
      }
    }
    &_fax {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include vw(
              $margin: 0 0 30
      );
      p {
        line-height: 1.2;
        @include vw(
                $margin: 0
        );
        a {
          @include vw(
                  $font-size: 36
          );
          font-weight: 600;
          color: #096791;

        }
      }
      span{
        font-weight: bold;
        @include vw(
                $font-size: 22
        );
        a{
          font-weight: bold;
        }
      }
    }
    &_time {
      @include vw(
              $font-size: 22
      );
      font-weight: bold;
    }
  }
  &_line {
    background: #59595c;
    width: 100%;
    @include vw(
            $height: 30,
    $margin: 50 0 0
    );
  }
  &_mobile_button {
    display: none;
    @include respond-to(480) {
      display: block;
      text-align: center;
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      a {
        font-size: 24px;
        border: 1px solid #a26026;
        border-radius: 10px;
        width: 100%;
        text-decoration: none;
        font-weight: bold;
        color: #096791;
        background-color: #fff;
        display: block;
        padding: 5px 0;
        max-width: 310px;
        margin: 0 auto;
      }
    }
  }
}