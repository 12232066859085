html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
menu, article, section, nav, footer, figure, var {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    font-weight:normal;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section{
    display:block;
}
ol, ul, menu{
    list-style: none;
}

blockquote, q{
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after{
    content: '';
    content: none;
}

:focus{
    outline: 0;
}
a:link, a:visited, a:hover, a:focus, a:active {
    outline: 0;
}
ins{
    text-decoration: none;
}

del{
    text-decoration: line-through;
}

table{
    border-collapse: collapse;
    border-spacing: 0;
}
html{
    font-size: 100.01%;
    font-family: sans-serif;
    color: #333;
}
body{
    position:relative;	
    font-family: sans-serif;
    color: #333;
	font-size: 24px;
}
b, strong{
    font-weight:bold;
}
button:hover{
    cursor:pointer;
}
a,input,button {
	outline: none;
}
a{
    text-decoration: none;
	cursor: pointer;
	color: #333;
    text-decoration:underline;
}
a:hover,
a:active,
a:focus{
	color: #333;
	text-decoration: none;
}
var{
    font-style:normal;
}

