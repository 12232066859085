@import "varibles";

@font-face {
  font-family: "Roboto";
  src: url('/fonts/Roboto.ttf');
  src: url('/fonts/Roboto?#iefix') format('embedded-opentype'),
  url('/fonts/Roboto.woff') format('woff'),
  url('/fonts/Roboto.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SignPainter";
  src: url('/fonts/SignPainter.ttf');
  src: url('/fonts/Roboto?#iefix') format('embedded-opentype'),
  url('/fonts/SignPainter.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  min-height: 100%;
}

body {
  @include vw(
          $font-size: 18
  );
  font-family: $ff;
  color: $dark;
  line-height: 1.4;
  position: initial;
  min-height: 100%;
  background-color: #fff;
}

p {
  @include vw($font-size: 18,
  $margin-bottom: 30);
  line-height: 1.6;
}

a {
  color: $dark;
  transition: all 0.1s ease-in;
  text-decoration: none;
  &:hover, &:active, &:focus {
    text-decoration: underline;
  }
}

img {
  max-width: 100%;
}

sup {
  font-size: 60%;
}

.g-site_width {
  @include vw($max-width: 1170);
  @include vw($padding: 0 15);
  width: 100%;
  margin: 0 auto;
  @include respond-to(small) {
    padding: 0 15px;
  }
;
}

/******* HEADER *******/
.g-header {
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  &:after,
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #a26026;
    @include vw(
            $height: 2
    );
  }
  &:before {
    @include vw(
            $bottom: 4
    );
  }
  &_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include vw(
            $padding: 20 0
    );
    @include respond-to(small) {
      padding: 10px 0;
    }
    &_logo {
      display: flex;
      justify-content: center;
      text-align: center;
      @include respond-to(small) {
        text-align: left;
        order: 1;
        display: block;
      }
    }
    nav {
      display: flex;
      justify-content: space-between;
      a {
        color: $dark;
        font-weight: 600;
        font-family: $ff;
        display: inline-block;
        @include vw(
                $font-size: 18,
                $padding: 0 15,
                $border-right: 2 solid $dark
        );
        &:last-child {
          border: none;
          color: #096791;
          padding-right: 0;
        }
        &:hover,
        &:active,
        &:focus {
          text-decoration: underline;
        }
        @include respond-to(small){
          border-right: none;
          width: 100%;
          font-size: 18px;
          padding: 10px 0;
          text-decoration: none;
        }
      }
      @include respond-to(small) {
        order: 8;
        flex-basis: 100%;
        flex-direction: column;
        align-items: center;
        display: none;

        &.open {
          display: flex;
          animation: click-expand .3s linear 0s;
          text-align: center;
        }
      }
    }
    &_icon {
      display: none;
      z-index: 99999999;
      position: absolute;
      right: 15px;
      top: 18px;
      @include respond-to(small) {
        display: block;
        width: 30px;
        height: 25px;
        margin: 10px;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
        float: right;
      }
      @include respond-to(xs) {
        margin: 10px 0;
      }
      span {
        display: block;
        position: absolute;
        height: 3px;
        width: 50%;
        background: $dark;
        opacity: 1;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
        &:nth-child(even) {
          left: 50%;
        }
        &:nth-child(odd) {
          left: 0;
        }
        &:nth-child(1),
        &:nth-child(2) {
          top: 0;
        }
        &:nth-child(3),
        &:nth-child(4) {
          top: 11px;
        }
        &:nth-child(5),
        &:nth-child(6) {
          top: 22px;
        }
      }
      &.open {
        span {
          &:nth-child(1),
          &:nth-child(6) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          &:nth-child(2),
          &:nth-child(5) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
          &:nth-child(1) {
            left: 5px;
            top: 9px;
          }
          &:nth-child(2) {
            left: calc(50% - 5px);
            top: 9px;
          }
          &:nth-child(3) {
            left: -50%;
            opacity: 0;
          }
          &:nth-child(4) {
            left: 100%;
            opacity: 0;
          }
          &:nth-child(5) {
            left: 5px;
            top: 14px;
          }
          &:nth-child(6) {
            left: calc(50% - 5px);
            top: 14px;
          }
        }
      }
    }
  }

}

/******* FOOTER *******/

.g-footer {
  background: #59595c;
  &_map{
    display: flex;
    iframe{
      width: 100%;
    }
  }
  &_copyright {
    text-align: center;
    color: #fff;
    @include vw(
            $font-size: 16,
            $padding: 25 0
    );
    @include respond-to(480){
      padding-bottom: 60px;
    }
  }
}
